import React from "react";

export default function HeroBanner() {
  const handleClickScroll = () => {
    const element = document.getElementById("contact");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="bg-[url('../src/assets/hero_banner.png')] bg-cover bg-center max-w-full m-auto p-12 md:p15 lg:p-20">
      <div className="m-auto bg-gradient-to-b from-gray-900 opacity-90 mt-10 max-w-4xl">
        <div className="flex flex-col items-center justify-center">
          <h1 className="mb-2 mt-3 text-2xl md:text-3xl lg:text-4xl font-bold text-center text-white p-6">
            Specialized provider of technical Downhole Fishing Services for the
            Drilling and Workover Operations of the Oil and Gas Industry
          </h1>
          {/* <p className="text-lg text-center text-white">
                    Text Here
                    </p> */}
          <div className="mt-4">
            <button
              className="px-6 py-2 mb-5 text-center text-white bg-indigo-600 rounded-md shadow-md"
              onClick={handleClickScroll}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
