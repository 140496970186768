import Products from './components/Products';
import About from './components/About';
import Team from './components/Team';
import './App.css';

function App() {
  return (
    <>
        <About />
        <Products />
        <Team />
    </>
  );
}

export default App;
