import React, { useState } from 'react';
import ImgOne from '../assets/1.png';
import ImgTwo from '../assets/2.png';
import ImgThree from '../assets/3.png';
import ImgFour from '../assets/4.png';
import ImgFive from '../assets/5.png';
import ImgSix from '../assets/6.png';
import ImgSeven from '../assets/7.png';

const PopupWindow = ({ content, onClose }) => (
  <div
    className="fixed top-0 left-0 w-full h-full flex items-center justify-center"
    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
    onClick={onClose}
  >
    <div
      className="w-[1200px] p-6 rounded-md bg-white relative"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="absolute top-2 right-2 text-xl font-bold bg-white p-1 rounded"
        onClick={onClose}
      >
        &times;
      </button>
      <h2 className="text-xl font-bold mb-4">{content.title}</h2>
      {content.moreInfo}
    </div>
  </div>
);



const Products = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  const products = [
    {
      id: 1,
      image: ImgOne,
      title: 'MILLING AND UNDERREAMING',
      description:
        'Milling services are provided through Anfield’s team of experienced fishing tool supervisors and a comprehensive line of milling tools.',
      moreInfo: (
        <>
          <p className="text-base mb-2">
            We offer all common types of mills including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li className="text-base">Junk Mills</li>
            <li className="text-base">Pilot Mills</li>
            <li className="text-base">Taper Mills</li>
            <li className="text-base">Section mills for casing exits</li>
          </ul>
          <h2 className="text-xl font-bold mt-4 mb-2">Underreamers</h2>
          <p className="text-base">
            Underreaming services are available to enlarge the well-bore using
            field tested retractable arm tools that are fitted with milled tooth
            cutters. Tool body and finished hole sizes range from 4-3/4″ to 40″.
          </p>
        </>
      ),
    },
    {
      id: 2,
      image: ImgTwo,
      title: 'CASING EXIT SYSTEMS',
      description:
        'Anfield Services Ltd. now provides the ITS Easy-Trak™ Casing Departure System, a unique whipstock/multi-mill combination tool used for window milling and sidetracking operations.',
      moreInfo: (
        <>
          <p className="text-base">
          Designed and manufactured to provide a reliable choice to casing exit operations, the Easy-Trak™ Casing Departure System is a one-trip system that facilitates the quick cutting of a uniform and usable window without the need to change the components of the bottom-hole assembly.<br></br><br></br>
          Available in a wide range of standard and custom-made sizes using whipstocks that can be either retrieved or permanently set, the Easy-Trak™ Casing Departure System offers the flexibility to meet a variety of applications.<br></br><br></br>
          A trouble-free job with minimal downtime is every operator’s goal. Our Easy-Trak™ Casing Departure Systems provide the reliability to ensure a smooth, successful and efficient outcome to all window milling projects.
          </p>
        </>
      ),
    },
    {
      id: 3,
      image: ImgThree,
      title: 'CHART TORQUING SERVICES',
      description:
        'Mobile/In House Chart Torquing Services available. Hydraulic Make-Up/Break-Out unit for all tubulars.',
      moreInfo: (
        <>
          <h2 className="text-xl font-bold mt-4 mb-2">Ranges and Dimensions</h2>
          <ul className="list-disc list-inside mb-4">
            <li className="text-base">O.D. Range – Base Package: 7″ to 11″</li>
            <li className="text-base">Torque Range – Make-Up: 100,000 ft-lbs</li>
            <li className="text-base">Torque Range – Break-Out: 150,000 ft-lbs</li>
            <li className="text-base">Arm Spacing Adjustment: 6ft or more</li>
            <li className="text-base">Dimensions: 97″W x 90″H x 112″D</li>
            <li className="text-base">Weight: 5000 lbs</li>
          </ul>
          <br></br>
          <h2 className="text-xl font-bold mt-4 mb-2">All of our machines feature the following:</h2>
          <ul className="list-disc list-inside mb-4">
            <li className="text-base">Safe and Easy to Operate</li>
            <li className="text-base">Compact and Portable</li>
            <li className="text-base">Instantly Converts between Make-Up and Break-Out Operation</li>
            <li className="text-base">Built in Backup Arm</li>
            <li className="text-base">Accurate Torque Control</li>
            <li className="text-base">Easy Torque Adjustments</li>
            <li className="text-base">Operates in Horizontal or Vertical Position</li>
            <li className="text-base">Multiple Power Pack Options:</li>
            <p>Diesel, Electric, Gasoline, Pneumatic/Air, Rig’s hydraulic system</p>
            <li className="text-base">Optional Accessories:</li>
            <p>TorqueShark™ Torque Recording System, Reducer Package, Bit Breaker, Additional Links, Jaws for larger O.Ds, DPT – Drill pipe package.</p>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      image: ImgFour,
      title: 'CERTIFIED EQUIPMENT RACKS',
      description:
        'Engineered/Certified Equipment Racks are available at Anfield Services Ltd. All racks are custom built, load tested and MPI inspected.',
      moreInfo: (
        <>
          <p className="text-base">
          Anfield Services Ltd. offers custom-engineered and certified equipment racks designed to meet the specific needs of your operation. Our equipment racks are built to the highest quality standards, ensuring safe and efficient storage and transportation of your valuable tools and equipment.<br></br><br></br>
          Each equipment rack is meticulously crafted with robust materials and goes through rigorous load testing to guarantee its durability and performance under various load conditions. In addition, our equipment racks undergo Magnetic Particle Inspection (MPI) to identify and address any potential surface and subsurface flaws, ensuring maximum safety and reliability.
          </p>
       </>
      ),
    },
    {
      id: 5,
      image: ImgFive,
      title: 'FABRICATION SERVICES',
      description:
        'ASL Fabricators at Anfield Services Ltd. provides specialized, high-quality service',
      moreInfo: (
        <>
          <ul className="list-disc list-inside mb-4">
            <li className="text-base">Redress of Oilfield Equipment</li>
            <li className="text-base">Application of Hard Metals</li>
            <li className="text-base">General Fabrication</li>
            <li className="text-base">Certified Equipment Racks for Warehouse Storage</li>
            <li className="text-base">Tank Building and Tank Stands</li>
            <li className="text-base">Skid Manufacture</li>
          </ul>
        </>
      ),
    },
    {
      id: 6,
      image: ImgSix,
      title: 'FISHING TOOLS',
      description:
        'Our fishing capabilities extend from wireline and 1″ tubing to 42″ casing, open and cased hole, external and internal catch fishing.',
      moreInfo: (
        <>
          <p className="text-base mb-2">
          Anfield Services Ltd. provides state-of-the-art equipment and highly qualified personnel trained in recovery of equipment lost or stuck downhole. Our extensive knowledge and experience in fishing safely and efficiently make us the best choice for getting your drilling and completion programs back on track with the least impact to your risk and NPT. From stuck-pipe and BHA retrieval services to simple junk removal, if it’s been stuck or lost in a well, we’ve probably already removed it successfully. Our fishing capabilities extend from wireline and 1″ tubing to 42″ casing, open and cased hole, external and internal catch fishing.
          </p>
          <h2 className="text-xl font-bold mt-4 mb-2">Our line of tools include:</h2>
          <ul className="list-disc list-inside mb-4">
            <li className="text-base">Overshots</li>
            <li className="text-base">Bumpersubs, Jars and Accelerators</li>
            <li className="text-base">Spears</li>
            <li className="text-base">Washpipe</li>
            <li className="text-base">Reverse Circulating Junk Baskets (RCJBs)</li>
            <li className="text-base">ADT junk retrievers</li>
            <li className="text-base">Other Specialty tools</li>
          </ul>
        </>
      ),
    },
    {
      id: 7,
      image: ImgSeven,
      title: 'PBL MULTIPLE ACTIVATION BYPASS SYSTEM',
      description:
        'DSI PBL Multiple Activation Bypass System is the leading multi-cycle circulating tool in the Oil & Gas industry today.',
      moreInfo: (
        <>
          <div className='pb-6'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/I3pA89ipiqs?si=KSiVcxRDrN3fVQFO?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{margin:"auto"}}></iframe>
          </div>
          <p className="text-base mb-2">
          DSI PBL Multiple Activation Bypass System is the leading multi-cycle circulating tool in the Oil & Gas industry today. Simple and robust, PBL Tool consists of a “Main Body” which houses all the major components required to enable activation, deactivation and proper functioning of the tool. A “Catcher Sub” located below the Main Body contains the “Catcher Cage” enabling the spent balls / darts to be captured within it.
          </p>
        </>
      ),
    },
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMoreInfoClick = (product) => {
    setPopupContent(product);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="bg-gray-100">
        <h1 className="text-2xl font-semibold text-center pt-8 mb-6">
          Products and Services
        </h1>
        <div className="flex flex-wrap justify-center max-w-sm md:max-w-3xl lg:max-w-5xl m-auto">
          {products.map((product, index) => (
            <div
              key={product.id}
              className={`md:w-1/2 lg:w-1/3 p-4 mb-10 lg:mb-5 ${
                hoveredIndex === index ? 'scale-105' : ''
              }`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="w-6/12 sm:w-6/12 m-auto mb-5">
                <img
                  src={product.image}
                  alt={product.title}
                  className="rounded max-w-full h-auto align-middle border-none"
                />
              </div>
              <h3 className="text-center text-lg font-medium">
                {product.title}
              </h3>
              <p className="text-center text-sm">{product.description}</p>
              {hoveredIndex === index && (
                <div
                  className="fixed top-0 left-0 w-full h-full flex items-center justify-center"
                  style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
                  onClick={() => handleMoreInfoClick(product)}
                >
                  <div className="p-6 rounded-md cursor-pointer">
                    <p className="text-white font-bold text-xl">More Info</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showPopup && (
        <PopupWindow content={popupContent} onClose={handleClosePopup} />
      )}
    </>
  );
};

export default Products;
