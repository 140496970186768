import React, { useState } from 'react';
import Logo from "../assets/anfield_logo_white.png";
import dsiImg from "../assets/DSI.png";
import banderaImg from "../assets/bandera.png";

const partners = [
  {
    name: 'Drilling Systems Int\'l',
    imageUrl: dsiImg,
    videoUrl: 'https://www.youtube.com/embed/I3pA89ipiqs?si=OxZ3Tg67HlkaDncD'
  },
  {
    name: 'Bandera Oil Tools',
    imageUrl: banderaImg,
  },
];

const Footer = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const openPopup = (url) => {
    setVideoUrl(url);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setVideoUrl("");
  };

  return (
    <footer
  className="bg-gray-800 lg:text-left text-white" id="contact">
  <div
    className="items-center justify-center border-b-2 border-gray-900 pt-4 dark:border-gray-700 lg:justify-between">
    <div className="pl-4 mr-12 hidden lg:block">
      <span>Our Partners:</span>
    </div>
    <div className="flex flex-row gap-4 justify-center mx-auto max-w-[1000px]">
        {partners.map((partner) => (
          <div key={partner.name} className="text-center">
            <div
              className="w-[80px] md:w-[80px] lg:w-[100px] mx-auto mb-4 border-2 border-gray-300 overflow-hidden cursor-pointer"
              onClick={() => partner.videoUrl && openPopup(partner.videoUrl)}
            >
              <img
                src={partner.imageUrl}
                alt={partner.name}
                className="object-cover bg-white w-full h-full"
              />
            </div>
            <h3 className="font-medium mb-2 hidden md:block">{partner.name}</h3>
          </div>
        ))}
      </div>

      {/* Popup */}
      {popupOpen && (
        <div className="fixed top-0 left-0 w-full h-full items-center justify-center bg-opacity-75 bg-black z-50" onClick={closePopup}>
          <div className="p-6 w-3/4 rounded-lg" style={{ margin: "25% auto"}}>
            <div className="relative w-full" style={{ paddingBottom: "56.25%" }}>
              <iframe
                src={videoUrl + "?autoplay=1"}
                title="YouTube Video"  
                className="absolute top-0 w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 text-white cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>
      )}
  </div>
  <div className="mx-6 py-6 text-center md:text-left">
    <div className='flex items-center justify-center pb-6'>
        <img
                className="h-[80px]"
                src= {Logo}
                alt="Workflow"
            />
    </div>
    <div className="m-auto max-w-6xl grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-2">
      <div className="">
        <p>
        ASL is accepted and approved to provide Wellbore Intervention Specialists, Rental Tools and associated equipment to the local and international operators in the Region. We have implemented our Quality, Health, Safety and Environment Management System (QHSEMS) to help us develop a safe culture to prevent accidents on our facilities and worksites.
        </p>
      </div>
      
      <div>
        <p className="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
          </svg>
          Lot 8, Edoo’s Industrial Estate, La Romain, Trinidad
        </p>
        <p className="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path
              d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg><a href='mailto:info@anfieldtt.com'>info@anfieldtt.com</a>
        </p>
        <p className="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd" />
          </svg>
          <a href='tel:1-868-225-8001'>1-868-225-8001</a>
        </p>
        <p className="flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              fillRule="evenodd"
              d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
              clipRule="evenodd" />
          </svg>
          1-868-652-5759
        </p>
      </div>
    </div>
  </div>
  <div className="bg-gray-800 p-6 text-center">
    <span>© 2023 Copyright: Anfield Services Ltd</span>
  </div>
</footer>
  );
}

export default Footer;