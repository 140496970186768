import React from 'react';
import michaelImg from '../assets/michael.png';
import kevinImg from '../assets/Kevin.png';
import joelImg from '../assets/joel.png';
import akeemImg from '../assets/akeem.png';
import kurnImg from '../assets/kurn.png';

const TeamMember = ({ name, position, imgSrc }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className="w-24 h-24 mb-4 bg-center bg-cover rounded-full"
        style={{ backgroundImage: `url(${imgSrc})` }}
      />
      <h3 className="text-lg font-semibold mb-1">{name}</h3>
      <p className="text-base text-gray-600">{position}</p>
    </div>
  );
};

const Teams = () => {
  const teamMembers = [
    {
      name: 'Michael Anton',
      position: 'CEO',
      imgSrc: michaelImg,
    },
    {
      name: 'Kevin Wakefield',
      position: 'Director',
      imgSrc: kevinImg,
    },
    {
      name: 'Joel Noreiga',
      position: 'HSE Coordinator',
      imgSrc: joelImg,
    },
    {
      name: 'Akeem Jones',
      position: 'Quality',
      imgSrc: akeemImg,
    },
    {
      name: 'Kurn Brewster',
      position: 'Toolshop Manager',
      imgSrc: kurnImg,
    },
  ];

  return (
    <div className="bg-white pt-8 mb-10" id="team">
      <h1 className="text-2xl font-semibold text-center mb-8">
        Meet Our Team
      </h1>
      <div className="flex flex-wrap justify-center gap-12">
        {teamMembers.map((teamMember, index) => (
          <TeamMember
            key={index}
            name={teamMember.name}
            position={teamMember.position}
            imgSrc={teamMember.imgSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default Teams;
