import React from 'react';

const About = () => {
  return (
    <div className="bg-white mb-8">
      <h2 className="text-2xl font-semibold pt-8 mb-6 text-center">About Anfield Services Limited</h2>
      <div className="max-w-[1000px] m-auto p-6">
        <p className="mb-4">
          Anfield Services Limited (ASL) was formed in 1989 to provide technical Downhole Fishing Services for the Drilling and Work over Operations of the Oil and Gas Industry. The company substantially increased its inventory of tools in 1996 and moved into its new facility in La Romaine, Trinidad. The owners of Anfield Services Limited have between them 100+ years of experience in the provision of technical and engineering supervision, fabrication, maintenance, application of tools and procedures used in Drilling and Work-over activities.
        </p>
        <p className="mb-4">
          We have implemented our Quality, Health, Safety and Environment Management System (QHSEMS) to help us develop a safe culture to prevent accidents on our facilities and worksites. ASL is accepted and approved to provide Wellbore Intervention Specialists, Rental Tools and associated equipment to the local and international operators in the Region.
        </p>
      </div>
    </div>
  );
};

export default About;
